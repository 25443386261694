
<template>
  <CRow align-horizontal="between">
    <CCol lg="6" class="ml-3">
      <p>{{ activemsg }}% Active</p>
      <p>{{ inactive }}% Inactive</p>
    </CCol>
    <CCol>
      <CChartDoughnut
        :datasets="defaultDatasets"
        :labels="['Active', 'Inactive']"
        :options="defaultOptions"
      />
    </CCol>
  </CRow>
</template>


<script>
import CChartDoughnut from "@coreui/vue-chartjs";

export default {
  name: "ActivityDonutChart",
  components: { CChartDoughnut },
  computed: {
    activemsg() {
      return 75;
    },
    inactive(){
      return 25;
    },
    defaultOptions() {
      return {
        legend: {
          display: false,
        },
        maintainAspectRatio: true,
      };
    },
    defaultDatasets() {
      return [
        {
          backgroundColor: ["#b88a00", "#5d5d5d"],
          data: [40, 20],
          //data: [this.activemsg, this.inactive]
        },
      ];
    },
  },
};
</script>