var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CWidgetDropdown", {
    staticClass: "h-80 header-widget",
    attrs: { color: _vm.color, header: _vm.title, text: _vm.subTitle },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c("div", { attrs: { "v-if": _vm.showValue } }, [
              _c("h3", [_vm._v(_vm._s(_vm.value) + _vm._s(_vm.unit))])
            ])
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c("CChartLineSimple", {
              staticClass: "mt-3 mx-3",
              staticStyle: { height: "70px" },
              attrs: {
                pointed: _vm.pointed,
                "data-points": _vm.dataPoints,
                "background-color": _vm.backgroundColor,
                "border-color": _vm.borderColor,
                "point-hover-background-color": _vm.pointHoverBackgroundColor,
                options: _vm.options,
                label: _vm.label,
                labels: _vm.labels
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }