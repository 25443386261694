var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { attrs: { "align-horizontal": "between" } },
    [
      _c("CCol", { staticClass: "ml-3", attrs: { lg: "6" } }, [
        _c("p", [_vm._v(_vm._s(_vm.activemsg) + "% Active")]),
        _c("p", [_vm._v(_vm._s(_vm.inactive) + "% Inactive")])
      ]),
      _c(
        "CCol",
        [
          _c("CChartDoughnut", {
            attrs: {
              datasets: _vm.defaultDatasets,
              labels: ["Active", "Inactive"],
              options: _vm.defaultOptions
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }