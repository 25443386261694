<template>
  <CWidgetDropdown
    :color="color"
    :header="title"
    :text="subTitle"
    class="h-80 header-widget"
  >
    <template #default>
      <div :v-if="showValue">
        <h3>{{ value }}{{ unit }}</h3>
      </div>
    </template>
    <template #footer>
      <CChartLineSimple
        :pointed="pointed"
        class="mt-3 mx-3"
        style="height:70px"
        :data-points="dataPoints"
        :background-color="backgroundColor"
        :border-color="borderColor"
        :point-hover-background-color="pointHoverBackgroundColor"
        :options="options"
        :label="label"
        :labels="labels"
      />
    </template>
  </CWidgetDropdown>
</template>


<script>
/** A Base Element / View Model for displaying Data in a bargraph as a widget
 * Also will optionally display the averaged displayed value
 */

import { CChartLineSimple, CChartBarSimple } from "@/components/chartLib";

const defaultOptions= () =>{
   return { elements: { 

    } 
    };
}


export default {
  name: "SingleDisplayWidget",
  components: {
    CChartLineSimple,
    CChartBarSimple
  },
  props: {
    pointed: {type: Boolean,default: true},
    labels: Array, // The labels to display for each value
    dataPoints: Array, // Must Be an Array of values
    label: String, // A global Label for the Data Type
    pointHoverBackgroundColor: String, 
    
    options: {
      type: Object, 
      default: defaultOptions,
    }, //
    title: String, // Give the Widget a Title
    subTitle: String, // Give the Widget a Sub-title
    units: String, //
    type: String, // Display a Bar Chart or Line Chart
    showValue: Boolean, // Flag to indicate if we want to show a value
    // TODO: Choose a Mode , Average, or last
    valueMode: String,
    precision: {
     type: Number,
     default: 1, 
    },
    color: {
      type: String, // Specifiy the background Color of the widget
      default: "gradient-info"
    },
    backgroundColor: String, // "rgba(255,255,255,.2)"
    borderColor: String // Colors the Line 
  },
  data() {
    return {};
  },
  computed: {
    value() {
      // TODO: Select which mode to display
      let total = 0.0;
      let count = 0;

      if (this.dataPoints) {
        let ret = this.calcAverage(this.dataPoints);
        return ret.toFixed(this.precision);
      } else {
        return 0;
      }
      //return this.calcAverage();
    },
    unit() {
      return this.units;
    }
  },
  methods: {
    calcAverage(items) {
      let total = 0.0;
      let count = 0;
      if (!items) return 0.0;
      for (var i = 0; i < items.length; i++) {
        
        let val = items[i];
        if (!isNaN(val)) {
          // Only Valid if value is a Number
          total += val;
          count += 1;
        }
      }

      return total / count;
    }
  }
};
</script>